import React from 'react'
import CookieConsent from 'react-cookie-consent'

// CSS
import 'styles/all.scss'

// Components
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }) => (
  <>
    <Header />
    <>{children}</>
    <Footer />
    <CookieConsent
      acceptOnScroll
      acceptOnScrollPercentage={20}
      location="bottom"
      style={{ backgroundColor: '#a1ce30', color: '#fff' }}
      buttonClasses="d-none"
    >
      <div className="container text-center font-weight-l">
        We gebruiken cookies om ervoor te zorgen dat onze website zo soepel mogelijk draait. Als u doorgaat met het gebruiken van de website, gaan we er vanuit dat u ermee instemt.
      </div>
    </CookieConsent>
  </>
)

export default Layout
