/* eslint-disable camelcase */
import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components'

import ParseContent from 'components/ParseContent';

// CSS
import 'styles/Footer.scss';

const Content = styled(ParseContent)`
& a{
  color: #fff;
}
`

const Footer = () => {
  function addGradient(imageProp) {
    return [
      imageProp,
      `linear-gradient(to top, rgba(0, 83, 112, 0) -61%, #008cbc 104%)`
    ].reverse();
  }

  const {
    footerNL
  } = useStaticQuery(graphql`
    query {
      footerNL: wordpressWpComponenten(slug: {eq: "footer"}) {
        id
        acf {
          background {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }

          section_clients {
            title
            clients {
              image {
                  localFile {
                    publicURL
                  }
              }
            }
          }

          section_footer_left {
            title
            links {
              title
              path
              slug
            }
          }

          section_footer_middle {
            title
            links {
              title
              path
              slug
            }
          }

          section_contact {
            title
            items {
              content
              icoon {
                localFile {
                  publicURL
                }
              }
            }
          }
          
          language_swap {
            text
            cta {
              label
              url {
                url
              }
            }
          }
        }
      }
    }
  `);

  const {
    acf: {
      background,
      section_clients,
      section_footer_left,
      section_footer_middle,
      section_contact,
      language_swap
    }
  } = footerNL;

  return (
    <>
      <div className="footer-switch mb-5 color-text-main">
        <div className="container flex-column flex-lg-row d-flex justify-content-center align-items-center py-4">
          <strong className="mr-lg-4 text-center text-lg-left mb-lg-0 mb-4 color-border font-size-xm">{language_swap.text}</strong>
          <a className="button-transparent button-transparent--outline" href={language_swap.cta.url.url}>
            {language_swap.cta.label}
          </a>
        </div>
      </div>

      <div className="footer position-relative">
        <BackgroundImage
          className="bg-image"
          fluid={addGradient(background.localFile.childImageSharp.fluid)}
        />

        <div className="container footer-top">
          <div className="row">
            <p className="footer-title">{section_clients.title}</p>
          </div>
        </div>
        <div className="container footer-bottom">
          <div className="row">
            <div className="col-md-11 footer-col footer-card mb-5">
              <div className="row justify-content-center align-items-center">
                {section_clients.clients.map((client, key) => (
                  <div
                    key={key}
                    className="col-6 mb-4 mt-4 col-sm-6 mb-sm-4 col-md-6 mb-md-4 col-lg-3 d-flex justify-content-sm-center  mb-lg-3 mt-lg-3"
                  >
                    <img
                      className="client-image"
                      alt=""
                      src={client.image.localFile.publicURL}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row justify-content-between">
            <div className="col-md-3 footer-col">
              <div className="row justify-content-start justify-content-lg-end">
                <div className="col-md-12 col-lg-12 col-xl-5 pl-md-4 pl-xl-0">
                  <p className="pl-md-2 pl-xl-2 footer-title">{section_footer_left.title}</p>
                </div>
              </div>
              <div className="row footer-card footer-card-left justify-content-end">
                <div className="col-md-12 col-lg-12 col-xl-5">
                  {section_footer_left.links.map((link, key) => (
                    <Link key={key} className="footer-link" to={link.path}>{link.title}</Link>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-md-3 footer-col">
              <div className="row justify-content-left">
                <div className="col-md-5">
                  <p className="footer-title footer-title-middle">{section_footer_middle.title}</p>
                </div>
              </div>
              <div className="row footer-card footer-card-middle justify-content-left">
                <div className="col-md-12">
                  {section_footer_middle.links.map((link, key) => (
                    <Link key={key} className="footer-link" to={link.path}>{link.title}</Link>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-md-5 footer-col">
              <div className="row justify-content-left">
                <div className="col-md-5">
                  <p className="footer-title footer-title-middle">{section_contact.title}</p>
                </div>
              </div>
              <div className="row footer-card footer-card-right justify-content-left">
                <div className="col-md-12">
                  {section_contact.items.map((item, key) => (
                    <div key={key} className="d-flex">
                      <img className="w-3 h-3" src={item.icoon.localFile.publicURL} alt="" />
                      <Content className="footer-card-right-content" content={item.content} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
